import "./Gallery.css"
import MyMenu from '../MyMenuComp/MyMenu.js';
import photos from "./GalleryPhotos/photos.js";

const Gallery = () => {
  return (
    <>
      <style>{`body { background-color: var(--red); }`}</style>
      <div className='header_div'>
        <h2 className='header_text_gallery'>Gallery</h2>
        <MyMenu pageName="gallery"/>
      </div>
      <div className="gallery_holder">
        {
          photos.map(item => (<div className="img_holder"><img src={item} alt={item.id} /></div>))
        }
      </div>
    </>
  )
};

export default Gallery;