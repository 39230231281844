import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router";
import './index.css';
import App from './App.js';
import Home from './components/HomeComp/Home.js';
import Details from './components/DetailsComp/Details.js';
import Gallery from './components/GalleryComp/Gallery.js';
import Rsvp from './components/RsvpComp/Rsvp.js';
import Registry from './components/RegistryComp/Registry.js';
import RsvpForm from './components/RsvpComp/RsvpForm.js';
import FormError from './components/RsvpComp/FormError.js';
import FormSuccess from './components/RsvpComp/FormSuccess.js';

const root = document.getElementById("root");

ReactDOM.createRoot(root).render( 
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="/details" element={<Details />} />
          <Route path="/registry" element={<Registry />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/rsvp" element={<Rsvp />} />
          <Route path="/rsvp-form" element={<RsvpForm />} />
          <Route path="/form-error" element={<FormError />} />
          <Route path="/form-success" element={<FormSuccess />} />
          <Route path="*" element={<Navigate to="/" />}  />
        </Route>
    </Routes>
  </BrowserRouter>
);