import "./Details.css"
import MyMenu from '../MyMenuComp/MyMenu.js';
import inspoPic from './inspo.jpg'

import { detailsPartOne, detailsPartTwo, detailsPartThree, detailsPartFour } from './detailTexts.js'

const Details = () => {

  return (
    <>
      <style>{`body { background-color: var(--blue); }`}</style>
      <div className='header_div'>
        <h2 className='header_text_details'>Details</h2>
        <MyMenu pageName="details"/>
      </div>
      <div className="body_div">
        
        <div className="details_section">
          <p className="details_p">{detailsPartOne}</p>
          <a href="https://g.co/kgs/XD6BsbW" target="_blank" rel="noreferrer" className="address_holder">
          <span>
            <p className="address_p">Red Butte Gardens</p>
            <p className="address_p">300 Wakara Way</p>
            <p className="address_p">Salt Lake City, UT</p>
            <p className="address_p">84108</p>
          </span>
          </a>
          <p className="details_p">{detailsPartTwo}</p>
          <p className="details_p">Please submit RSVPs by April 1<sup>st</sup></p>
        </div>
        <div className="details_section">
          <p className="details_title">TIMELINE</p>
          <p className="details_p">&nbsp;4:30 PM:&nbsp;&nbsp;Doors open</p>
          <p className="details_p">&nbsp;5:00 PM:&nbsp;&nbsp;Ceremony begins</p>
          <p className="details_p">&nbsp;5:30 PM:&nbsp;&nbsp;Cocktail hour</p>
          <p className="details_p">&nbsp;6:30 PM:&nbsp;&nbsp;Dinner</p>
          <p className="details_p">&nbsp;10:00 PM: Reception ends</p>
        </div>
        <div className="details_section">
          <p className="details_title">DRESSCODE</p>
          <p className="details_p">{detailsPartThree}</p>
          <p className="details_p">{detailsPartFour}</p>
        </div>
        <div className="single_img_holder">
          <img alt="Collage of outfits for inspiration" src={inspoPic} />
        </div>
      </div>
    </>
  )
};

export default Details;