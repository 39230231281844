import "./Rsvp.css"
import MyMenu from "../MyMenuComp/MyMenu.js";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function Rsvp() { 
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailBlank, setEmailBlank] = useState(false);
  const [emailFound, setEmailFound] = useState(false);

  function handleEmailChange(input) {
    setEmail(input.target.value);
  }

  function navigateToRsvpForm() {
    const emailObj = { email }
    const adjEmail = emailObj.email.toLowerCase();
    navigate('/rsvp-form', { state: { email: adjEmail } });
  }

  function handleCheckEmail() {
    const emailObj = { email }
    const adjEmail = emailObj.email.toLowerCase();
    if ( adjEmail === "" ) { setEmailBlank(true); return; }
    if ( emailBlank ) { setEmailBlank(false) }
    const data = { dataEmail: adjEmail };
    const request = new Request('/api/emailChecker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const func = async () => {
      fetch(request)
        .then(response => response.json())
        .then(data => {
          if (Object.keys(data).length !== 0) {
            setEmailFound(true);
          }
          else {
            navigateToRsvpForm();
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    func();
  }

  return (
    <>
      <style>{`body { background-color: var(--white); }`}</style>
      <div className='header_div'>
        <h2 className='header_text_rsvp'>Rsvp</h2>
        <MyMenu pageName="registry"/>
      </div>
      <div className="body_div">
        <p className="rsvp_p">
          Please enter your email to start the RSVP process. Only one response 
          is permitted per email. If you need to change any information after 
          submitting a response, please contact Madisen or Beckett directly.
        </p>
        <div className="email_entry" style={{flexDirection:'row', flexWrap:'wrap', alignItems:'center'}}>
          <p className="email_p" style={{margin: 0}}>Email:</p>
          <input value={email} onChange={handleEmailChange} className="text_input"/>
          <button onClick={handleCheckEmail} className="email_submit_button">
            <p className="email_submit_p">Submit</p>
          </button>
        </div>
        <div className="error_div">
          {emailBlank && <p className="error_p">Please enter an email before pressing submit.</p>}
          {emailFound && <p className="error_p">Sorry, the email you entered already exists within our system. 
            If you need to update your response, please contact Madisen or Beckett</p>}
        </div>
      </div>
    </>
  );
}

export default Rsvp;