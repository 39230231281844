import "./Rsvp.css"
import MyMenu from "../MyMenuComp/MyMenu.js";

const FormError = () => {
    return (
      <>
        <style>{`body { background-color: var(--white); }`}</style>
        <div className='header_div'>
          <h2 className='header_text_rsvp'>Uh Oh!</h2>
          <MyMenu pageName="registry"/>
        </div>
        <div className="body_div">
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            If you reached this page, it means that there was an error entering your response
          </p>
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            Please contact Madisen or Beckett, and we'll get this sorted out as soon as we can
          </p>
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            Sorry for the inconvenience!
          </p>
        </div>
      </>
    )
  };
  
  export default FormError;