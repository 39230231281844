import "./Rsvp.css"
import { useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';

function RsvpForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [guestInfo, setGuestInfo] = useState([]);
  const [nextGuestName, setNextGuestName] = useState("");
  const [nextGuestAttend, setNextGuestAttend] = useState(true);
  const [doneResponding, setDoneResponding] = useState(false);

  function handleNameChange(input) {
    setName(input.target.value);
  }

  function handleGuestNameChange(input) {
    setNextGuestName(input.target.value);
  }

  function handleGuestAttendChange() {
    setNextGuestAttend(!nextGuestAttend);
  }

  function navigateToSuccessPage() {
    navigate('/form-success', { replace: true });
  }

  function navigateToErrorPage() {
    navigate('/form-error', { replace: true });
  }

  function addGuest() {
    const uuid = require("uuid");
    const nameObj = { nextGuestName };
    const attendObj = { nextGuestAttend };
    const newGuest = {
      id: uuid.v4(),
      info: {
        name: nameObj.nextGuestName,
        attending: attendObj.nextGuestAttend
      }
    };
    setGuestInfo([...guestInfo, newGuest]);
    setNextGuestName("");
    setNextGuestAttend(true);
  }

  function removeGuestInfo(id) {
    setGuestInfo(guestInfo.filter(item => item.id !== id));
  };

  function inputDoneResponding() {
    setDoneResponding(true);
  }
  function inputNotDoneResponding() {
    setDoneResponding(false);
  }

  function handleSubmitRsvp() {
    if (!location.state.email) { navigateToErrorPage(); }
    const email = location.state.email;
    const nameObj = { name }
    const data = { dataEmail: email, dataName: nameObj.name, dataInfo: {guestInfo} };
    const request = new Request('/api/rsvpResponder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const func = async () => {
      fetch(request)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          navigateToSuccessPage();
        })
        .catch(error => {
          console.error(error);
          navigateToErrorPage();
        });
    }
    func();
  }

  return (
    <>
      <style>{`body { background-color: var(--white); }`}</style>
      <div className='header_div' style={{justifyContent: 'center'}}>
        <h2 className='header_text_rsvp' style={{paddingLeft: 0, textAlign: 'center'}}>Rsvp Form</h2>
      </div>

      {!doneResponding && 
      <div className="body_div">
        <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
          <p className="rsvp_p" style={{textAlign: 'justify', margin: 0, marginBottom:'10px'}}>
            Please fill out your name:
          </p>
          <input value={name} onChange={handleNameChange} className="text_input"/>
        </div>
        <p className="rsvp_p" style={{textAlign: 'justify', marginTop: '2em'}}>
          For each person in your household - including yourself -
          please fill out the following information and add them to your repsonses.
        </p>
        <div style={{alignSelf: 'flex-start'}}>
          <ul style={{marginBottom: '4em', padding: 0}}> <p className="rsvp_p">Your responses:</p>
            {guestInfo.map((guest) => (
              <li key={guest.id} style={{listStyleType: 'none'}}>
                <div style={ {display:'flex', flexDirection:'row', alignItems:'center'} }>
                  <button onClick={() => removeGuestInfo(guest.id)} className="close_button">
                    <p className="email_submit_p">X</p>
                  </button>
                  {!guest.info.attending && 
                    <div style={{marginLeft:'2em'}}>
                      <p className="rsvp_p" style={{margin: 0}}>{guest.info.name} is not attending</p>
                    </div>
                  }
                  {guest.info.attending && 
                    <div style={{marginLeft:'2em'}}>
                      <p className="rsvp_p" style={{margin: 0}}>{guest.info.name} is attending</p>
                    </div>
                  }
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="email_entry">
          <div style={{marginRight: '0.5em'}}>
            <div className="form_input_div">
              <p className="email_submit_p">Guest name:</p>
              <input value={nextGuestName} onChange={handleGuestNameChange} className="text_input"/>
            </div>
            <div className="form_input_div">
              <p className="email_submit_p">Are they attending: </p>
              <div style={{display: 'flex', flexDirection:'row', marginLeft: '0.5em', alignItems:'center'}}>
                <div><p className="email_submit_p" style={{marginLeft: '0.75em'}}>Yes</p></div>
                <input type="checkbox" checked={nextGuestAttend} onChange={handleGuestAttendChange} className="checkbox"/>
                <div><p className="email_submit_p" style={{marginLeft: '1em'}}>No</p></div>
                <input type="checkbox" checked={!nextGuestAttend} onChange={handleGuestAttendChange} className="checkbox"/>
              </div>
            </div>
          </div>
          <div>
            <button onClick={addGuest} className="email_submit_button">
            <p className="email_submit_p">Add response</p>
            </button>
          </div>
        </div>
        <div style={{marginTop:'2em'}}>
          <button onClick={inputDoneResponding} className="email_submit_button">
            <p className="email_submit_p">Click here when done entering responses</p>
          </button>
        </div>
        </div>}
        
        {doneResponding && 
        <div className="body_div">
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            After submitting this RSVP, you will be unable to use the email you entered
            to submit another response.
          </p>
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            If you need to edit your response after submitting, please contact Madisen or Beckett directly.
          </p>
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            Due to limited space at our venue, we are unable to accomodate anyone 
            under 18 (excluding immeadiate family) or anyone not included on the invitation.
          </p>
          <div style={{marginTop:'2em'}}>
            <button onClick={inputNotDoneResponding} className="email_submit_button">
              <p className="email_submit_p">Edit my responses</p>
            </button>
          </div>
          <div style={{marginTop:'2em'}}>
            <button onClick={handleSubmitRsvp} className="email_submit_button">
              <p className="email_submit_p">Submit RSVP</p>
            </button>
          </div>
        </div>}
    </>
  );
}

export default RsvpForm;