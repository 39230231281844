import "./MyMenu.css"
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';


const MyMenu = ({ pageName="home" }) => {
  const navigate = useNavigate();
  const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  const theme = createTheme({
    typography: {
      fontFamily: [
        '"Onest", serif',
      ].join(','),
      fontSize: (12 + Math.log(0.02 * vw) / Math.log(1.5)),
    },
    palette: {
      home: {
        main: '#14350A'
      },
      details: {
        main: '#FBF7F3'
      },
      registry: {
        main: '#14350A'
      },
      gallery: {
        main: '#E7D3E8'
      },
      rsvp: {
        main: '#611631'
      },
    },
  });
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl); 

  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToHome = () => {
    handleClose();
    navigate('/home');
  }

  const navigateToDetails = () => {
    handleClose();
    navigate('/details');
  }
  const navigateToRegistry = () => {
    handleClose();
    navigate('/registry');
  }
  const navigateToGallery = () => {
    handleClose();
    navigate('/gallery');
  }
  const navigateToRsvp = () => {
    handleClose();
    navigate('/rsvp');
  }

  return (
    <div className="my_menu_div">
      <ThemeProvider theme={theme}>
        <IconButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color={pageName}
          style={{justifyContent: 'center'}}
        >
          <MenuIcon color={pageName} className="menu_icon"/>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={
            { mt: "1px", "& .MuiMenu-paper": 
              { backgroundColor: "#FBF7F3", },
            }
          }
        >
          <MenuItem onClick={navigateToHome}>Home</MenuItem>
          <MenuItem onClick={navigateToDetails}>Details</MenuItem>
          <MenuItem onClick={navigateToRegistry}>Registry</MenuItem>
          <MenuItem onClick={navigateToGallery}>Gallery</MenuItem>
          <MenuItem onClick={navigateToRsvp}>RSVP</MenuItem>
        </Menu>
      </ThemeProvider>
    </div>
  );
};

export default MyMenu;