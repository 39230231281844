import pic01 from './01.jpg'
import pic02 from './02.jpg'
import pic03 from './03.jpg'
import pic04 from './04.jpg'
import pic05 from './05.jpg'
import pic06 from './06.jpg'
import pic07 from './07.jpg'
import pic08 from './08.jpg'
import pic09 from './09.jpg'
import pic10 from './10.jpg'
import pic11 from './11.jpg'
import pic12 from './12.jpg'
import pic13 from './13.jpg'
import pic14 from './14.jpg'
import pic15 from './15.jpg'
import pic16 from './16.jpg'
import pic17 from './17.jpg'
import pic18 from './18.jpg'
import pic19 from './19.jpg'
import pic20 from './20.jpg'
import pic21 from './21.jpg'
import pic22 from './22.jpg'

const photos = [
    pic08,
    pic22,
    pic20,
    pic11,
    pic09,
    pic14,
    pic05,
    pic03,
    pic17,
    pic02,
    pic21,
    pic04,
    pic13,
    pic15,
    pic16,
    pic01,
    pic10,
    pic12,
    pic19,
    pic06,
    pic18,
    pic07,
];

export default photos;