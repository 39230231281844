import "./Rsvp.css"
import MyMenu from "../../MyMenuComp/MyMenu.js";

const RsvpSuccess = () => {
    return (
      <>
        <style>{`body { background-color: var(--purple); }`}</style>
        <div className='header_div'>
          <h2 className='header_text_rsvp'>Success!</h2>
          <MyMenu pageName="rsvp"/>
        </div>
        <div className="body_div">
          <p className="rsvp_p" style={{textAlign: 'left'}}>
            If you reached this page, it means that your response has been successfully recorded.
          </p>
          <p className="rsvp_p" style={{textAlign: 'left'}}>
            Feel free to navigate to other pages of the site or close this window.
          </p>
          <p className="rsvp_p" style={{textAlign: 'center'}}>
            Thank you!
          </p>
        </div>
      </>
    )
  };
  
  export default RsvpSuccess;