import "./Rsvp.css"
import MyMenu from "../MyMenuComp/MyMenu.js";

const FormSuccess = () => {
    return (
      <>
        <style>{`body { background-color: var(--white); }`}</style>
        <div className='header_div'>
          <h2 className='header_text_rsvp'>Success!</h2>
          <MyMenu pageName="registry"/>
        </div>
        <div className="body_div">
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            If you reached this page, it means that your response has been successfully recorded
          </p>
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            Feel free to navigate to other pages of the site or close this window
          </p>
          <p className="rsvp_p" style={{textAlign: 'justify'}}>
            Thank you!
          </p>
        </div>
      </>
    )
  };
  
  export default FormSuccess;