import "./Registry.css"
import { registryPartOne, registryPartTwo, registryPartThree } from './registryTexts.js'
import MyMenu from '../MyMenuComp/MyMenu.js';
import pic00 from './RegistryPhotos/00.jpg';

const Registry = () => {
  return (
    <>
      <style>{`body { background-color: var(--light-green); }`}</style>
      <div className='header_div'>
        <h2 className='header_text_registry'>Registry</h2>
        <MyMenu pageName="registry"/>
      </div>
      <div className="body_div">
        <div className="sinlge_img_holder">
          <img alt="Photo-of-us-00" src={pic00}/>
        </div>
        <p className="registry_p">
          {registryPartOne} 
          <a href="https://www.target.com/gift-registry/gift/Beck-Madi-Target-Registry" rel="noreferrer" target="_blank">
            Target
          </a> and <a href="https://www.anthropologie.com/registry/listing?registryId=DBE4961A3691" rel="noreferrer" target="_blank">
            Anthropologie
          </a>
          {registryPartTwo} 
          <a href="https://venmo.com/u/Madisen-Bennett-1" rel="noreferrer" target="_blank">
            Venmo
          </a>
          .
        </p>
        <p className="registry_p">
          {registryPartThree}
        </p>
      </div>
    </>
  )
};

export default Registry;