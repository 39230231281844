import "./Rsvp.css"
import MyMenu from "../../MyMenuComp/MyMenu.js";
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

function Rsvp() { 
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  function handleEmailChange(input) {
    setEmail(input.target.value);
  }

  function handleNameChange(input) {
    setName(input.target.value);
  }

  function navigateToRsvpForm() {
    const emailObj = { email }
    const adjEmail = emailObj.email.toLowerCase();
    const nameObj = { name }
    const adjName = nameObj.name
    navigate('/rsvp-form', { state: { email: adjEmail , name: adjName } });
  }

  function handleCheckEmail() {
    const emailObj = { email }
    const adjEmail = emailObj.email.toLowerCase();
    if ( adjEmail === "" ) { setErrorMessage("Please enter an email before pressing submit."); return; }
    if ( errorMessage !== "" ) { setErrorMessage("") }
    const data = { dataEmail: adjEmail };
    const request = new Request('/api/emailChecker', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    const func = async () => {
      fetch(request)
        .then(response => response.json())
        .then(data => {
          if (Object.keys(data).length !== 0) {
            setErrorMessage("Sorry, the email you entered already exists within our system. If you need to update your response, please contact Madisen or Beckett");
          }
          else {
            navigateToRsvpForm();
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
    func();
  }

  return (
    <>
      <style>{`body { background-color: var(--purple); }`}</style>
      <div className='header_div'>
        <h2 className='header_text_rsvp'>Rsvp</h2>
        <MyMenu pageName="rsvp"/>
      </div>
      <div className="body_div">
        <p className="rsvp_p">
          Please enter your name and email:
        </p>
        <div className="spacer">
          <div className="entry_div">
            <p className="entry_p" style={{margin: 0}}>Name:</p>
            <input value={name} onChange={handleNameChange} className="rsvp_text_input"/>
          </div>
          <div className="entry_div">
            <p className="entry_p" style={{margin: 0}}>Email:</p>
            <input value={email} onChange={handleEmailChange} className="rsvp_text_input"/>
          </div>
        </div>
        <button onClick={handleCheckEmail} className="start_rsvp_button">
          <p className="start_rsvp_p">Start RSVPs</p>
        </button>       
        <div className="error_div">
          <p className="error_p">{errorMessage}</p>
        </div>
      </div>
    </>
  );
}

export default Rsvp;