import "./Home.css"
import MyMenu from '../MyMenuComp/MyMenu.js';
import { useNavigate } from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate();

  function navigateToRsvp() {
    navigate('/rsvp');
  }

  return (
    <>
      <style>{`body { background-color: var(--white); }`}</style>
      <style>{`.App { border: 4px solid var(--blue); }`}</style>
      <style>{`html, body, #root, .App { height: 99%; }`}</style>
      <div className='header_div' style={{height: '4em'}}>
        <h2 className='header_text_home'>Madisen + Beckett</h2>
        <MyMenu pageName="home"/>
      </div>
      <div className="big_title_holder">
        <h1 className="big_title">
          WE'RE GETTING MARRIED!
        </h1>
      </div>
      <div className="date_holder">
        <p className="home_text">&nbsp;&nbsp;&nbsp;23</p>
        <hr className="div_line"/>
        <p className="home_text">May</p>
        <hr className="div_line"/>
        <p className="home_text">2025</p>
      </div>
      <button className="rsvp_button" onClick={navigateToRsvp}>
        <h2 className="rsvp_text">see you there?</h2>
      </button>
    </>
  )
};

export default Home;